import AuthMethod from '@/components/auth/AuthMethod';
import Button from '@/components/button';
import Input from '@/components/common/input';
import Select from '@/components/select';
import { ToastError, ToastSuccess } from '@/components/toastify';
import { AREA_CODES, AUTH_METHOD } from '@/constants/index';
import IcFacebookSignup from '@/public/icons/ic-fb-signup.svg';
import IcGoogleSignup from '@/public/icons/ic-google-signup.svg';
import { get } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { loginWithEmailToken, loginWithPhoneOTP } from 'services/auth';
import FacebookLogin from 'react-facebook-login';
import * as yup from 'yup';
import { setAuthorization } from 'utils/axios.config';
import cookie from 'js-cookie';
import { getUserProfile, loginWithFacebook, loginWithGoogle } from 'services';
import { useRouter } from 'next/router';
import { useGoogleLogin } from '@react-oauth/google';

const { yupResolver } = require('@hookform/resolvers/yup');

function Login({ onNextStep, onSubmitValues }) {
  const fieldRef = useRef(0);
  const { PHONE_NUMBER, EMAIL } = AUTH_METHOD;

  const [areaCode, setAreaCode] = useState(AREA_CODES[0].value);
  const [activeMethod, setActiveMethod] = useState(PHONE_NUMBER);
  const router = useRouter();

  const createAccountSchema = yup.object().shape({
    phoneNumber: yup.lazy(() => {
      if (activeMethod === PHONE_NUMBER) {
        return yup.string().required('Please enter your Phone Number.');
      }
      return yup.string().notRequired();
    }),
    email: yup.lazy(() => {
      if (activeMethod === EMAIL) {
        return yup
          .string()
          .required('Please enter your Email.')
          .email('Please enter correct Email.');
      }
      return yup.string().notRequired();
    }),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(createAccountSchema),
  });

  const onLoginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLoginWithGoogle(tokenResponse),
  });

  const onSubmitCreateAccountStep = async (values) => {
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'flex';
    try {
      const result =
        activeMethod === EMAIL
          ? await loginWithEmailToken({
              email: values.email,
              redirect_url: process.env.NEXT_PUBLIC_LANDING_URL,
              method: 'login',
            })
          : await loginWithPhoneOTP({
              phone_number: areaCode + values.phoneNumber,
            });

      if (get(result, 'data.status') === 1 || get(result, 'data.data')) {
        const model = {
          method: activeMethod,
          areaCode: areaCode,
          value:
            activeMethod === PHONE_NUMBER ? values.phoneNumber : values.email,
          endcode_data: get(result, 'data.data.endcode_data'),
          expireTime: get(result, 'data.data.expire_time_otp'),
          type: 'login',
        };
        onNextStep();
        onSubmitValues(model);
      }
    } catch (error) {
      overlay.style.display = 'none';
      // const { data } = error.response;
      ToastError('User with Phone number does not exist');
    }
  };

  const handleGetUserProfile = (dataLogin) => {
    if (dataLogin?.user_id) {
      getUserProfile(dataLogin.user_id)
        .then((res) => {
          const { data } = res.data;

          if (res.data?.status == 1) {
            const fullname = data?.last_name
              ? data?.first_name + ' ' + data?.last_name
              : data?.first_name;
            localStorage.setItem('logged_user_name', fullname);
            localStorage.setItem('logged_user_email', data?.email);
            localStorage.setItem('logged_user_lastname', data?.last_name);
            localStorage.setItem('logged_user_firstname', data?.first_name);
            localStorage.setItem('logged_user_photo', data?.avatar);
            localStorage.setItem('username', fullname);

            //cookies
            cookie.set('logged_user_name', fullname);
            cookie.set('logged_user_email', data?.email);
            cookie.set('logged_user_lastname', data?.last_name);
            cookie.set('logged_user_firstname', data?.first_name);
            cookie.set('logged_user_photo', data?.avatar);
            cookie.set('username', fullname);

            cookie.set('user_id', dataLogin.user_id, {
              expires: 2 / 24,
            });
          } else {
            const { message } = res.data;
            ToastError({ message });
          }
        })
        .catch((error) => {
          const response = error.response;
          ToastError(messages[response?.data?.message]);
        });
    }
  };

  const responseFacebook = (res) => {
    if (res.accessToken) {
      loginWithFacebook({ access_token: res.accessToken })
        .then((resp) => {
          if (resp.data.status == 1) {
            const { user_id, tokens } = resp.data;

            setAuthorization(tokens?.access);
            localStorage.setItem('logged_user_id', user_id);
            localStorage.setItem('user_token', tokens?.access);

            cookie.set('user_id', user_id);
            cookie.set('token', tokens?.access);
            handleGetUserProfile({ user_id });

            ToastSuccess('Login successfully.');
            if (router.query?.redirect)
              router.push(decodeURIComponent(router.query?.redirect));
            else router.push('/profile');
            ga.event({
              action: 'Publication | Login with Facebook Success',
              params: {
                category: 'Login',
                label: 'Social Login',
              },
            });
            ga.matomoEvent({
              category: 'Social Login',
              action: 'Login with Facebook Success',
              name: 'Facebook ',
            });
          } else {
            ga.event({
              action: 'Publication | Login Facebook Failure',
              params: {
                category: 'Login',
                label: 'Social Login',
              },
            });
            ga.matomoEvent({
              category: 'Social Login',
              action: 'Login Facebook Failure',
              name: 'Facebook ',
            });
          }
        })
        .catch((error) => {
          if (error?.response?.data)
            ToastError(error?.response?.data?.message);
        });
    }
  };

  const handleLoginWithGoogle = (tokenResponse) => {
    if (tokenResponse?.access_token) {
      loginWithGoogle({ access_token: tokenResponse?.access_token })
        .then((res) => {
          if (res.data.status == 1) {
            const { user_id, tokens } = res.data;
            $('#login-signup').modal('hide');

            setAuthorization(tokens?.access);
            localStorage.setItem('logged_user_id', user_id);
            localStorage.setItem('user_token', tokens?.access);

            cookie.set('user_id', user_id);
            cookie.set('token', tokens?.access);
            handleGetUserProfile({ user_id });
            ToastSuccess('Login successfully.');
            if (router.query?.redirect)
              router.push(decodeURIComponent(router.query?.redirect));
            else router.push('/profile');
            ga.event({
              action: 'Publication | Login with Google Success',
              params: {
                category: 'Login',
                label: 'Social Login',
              },
            });
            ga.matomoEvent({
              category: 'Social Login',
              action: 'Login with Google Success',
              name: 'Google ',
            });
          } else {
            const { message } = res.data;
            ToastError({ message });
          }
        })
        .catch((error) => {
          if (error?.response?.data)
            ToastError(error?.response?.data?.message);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="auth-content__top create-account-top">
        <div className="title">Welcome back!</div>
        <div className="sub-title">
          Please log in to access your HHWT account
        </div>
      </div>
      <div className="auth-content__middle">
        <AuthMethod
          activeMethod={activeMethod}
          onSetActiveMethod={setActiveMethod}
          onClearErrors={clearErrors}
        />

        <form
          ref={fieldRef}
          onSubmit={handleSubmit(onSubmitCreateAccountStep)}
          className="auth-form"
        >
          <div className="auth-form__create-account">
            {activeMethod === PHONE_NUMBER ? (
              <div className="form-item">
                <div className="form-item__label">Phone Number</div>
                <div className="form-item__field">
                  <div className="group">
                    <div className="selection">
                      <Select
                        options={AREA_CODES}
                        value={areaCode}
                        getOptionLabel={(e) => (
                          <div
                            className="area-code-option"
                            style={{
                              width: fieldRef?.current?.clientWidth
                                ? `calc(${fieldRef.current.clientWidth - 24}px)`
                                : 'auto',
                            }}
                          >
                            <div className="option-flag">
                              <Image
                                src={e.icon}
                                layout="fill"
                                alt="flag-icon"
                              />
                            </div>
                            <span className="option-label">{e.label}</span>
                            <span className="option-value">({e.value})</span>
                          </div>
                        )}
                        onChange={(opt) => setAreaCode(opt.value)}
                      />
                    </div>
                    <div className="inputting">
                      <Input
                        type="number"
                        placeholder="Enter your Phone Number"
                        hasError={get(errors, 'phoneNumber.message')}
                        register={register('phoneNumber')}
                      />
                    </div>
                  </div>
                </div>
                {get(errors, 'phoneNumber.message') && (
                  <div className="form-item__err-message">
                    {get(errors, 'phoneNumber.message')}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-item">
                <div className="form-item__label">Email</div>
                <div className="form-item__field">
                  <Input
                    type="text"
                    placeholder="Enter your Email"
                    hasError={get(errors, 'email.message')}
                    register={register('email')}
                  />
                </div>
                {get(errors, 'email.message') && (
                  <div className="form-item__err-message">
                    {get(errors, 'email.message')}
                  </div>
                )}
              </div>
            )}
            <div className="form-item">
              <Button classNames="login-btn" title="Log in" type="submit" />
            </div>
          </div>
        </form>

        <div className="auth-or">or Log in with</div>
        <div className="auth-social">
          <Button
            classNames="auth-btn has-icon"
            icon={IcGoogleSignup}
            onClick={onLoginWithGoogle}
            tooltip="Log in with Google"
          />
          <FacebookLogin
            textButton=""
            cssClass="auth-btn has-icon"
            disableMobileRedirect={true}
            appId={process.env.NEXT_PUBLIC_FB_CLIENT_ID}
            autoLoad={false}
            fields="name,email,picture"
            icon={
              <img
                width={24}
                height={24}
                src={IcFacebookSignup.src}
                alt="icon"
              />
            }
            callback={responseFacebook}
          />
        </div>
        <div className="auth-policy">
          Don't have an account?{' '}
          <Link href="/signup">
            <a>Sign up</a>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
