import { AUTH_METHOD_LIST } from '@/constants/index';
import _ from 'lodash';
import React from 'react';

function AuthMethod({ activeMethod, onSetActiveMethod, onClearErrors }) {
  return (
    <ul className="auth-method-selection">
      {_.map(AUTH_METHOD_LIST, (method) => (
        <li
          key={method.value}
          className={`auth-method ${
            method.value === activeMethod ? 'active' : ''
          }`}
          onClick={() => {
            onSetActiveMethod(method.value);
            onClearErrors();
          }}
        >
          {method.name}
        </li>
      ))}
    </ul>
  );
}

export default AuthMethod;
