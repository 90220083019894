import Login from '@/components/auth/Login';
import BusinessMobileHeader from '@/components/auth/MobileHeader';
import BusinessSidebar from '@/components/auth/Sidebar';
import OtpVerifying from '@/components/auth/SignUp/Steps/CreateAccount/OtpVerifying';
import { ToastError, ToastSuccess } from '@/components/toastify';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import IcLoadingLogin from '@/public/icons/ic-loading-login.svg';
import Image from 'next/image';

const [SIGN_IN, VERIFY] = [1, 2];

function SignIn() {
  const router = useRouter();

  const { isMobile } = useWindowDimensions();
  const [signInStep, setSignInStep] = useState(SIGN_IN);
  const [submitValues, setSubmitValues] = useState({});

  const handleLoginSuccess = (value) => {
    if (value) {
      ToastSuccess('Login successfully.');
      if (router.query?.redirect)
        router.push(decodeURIComponent(router.query?.redirect));
      else router.push('/profile');
    } else {
      ToastError('OTP wrong.');
    }
  };

  const handleStepContentActive = useCallback(() => {
    switch (signInStep) {
      case SIGN_IN:
        return (
          <Login
            onNextStep={() => setSignInStep(signInStep + 1)}
            onSubmitValues={(values) => {
              setSubmitValues((prevValues) => ({
                ...prevValues,
                ...values,
              }));
            }}
          />
        );

      case VERIFY:
        return (
          <OtpVerifying
            authValues={submitValues}
            onBackStep={() => setSignInStep(signInStep - 1)}
            onNextStep={(value) => handleLoginSuccess(value)}
          />
        );

      default:
        break;
    }
  }, [signInStep, submitValues]);

  return (
    <div className="auth-page">
      <div className="overlay" id="overlay">
        <div className="overlay__loading-box">
          <img
            src={IcLoadingLogin.src}
            alt="loading-ic"
            className="overlay__rotating-image"
          />
          <div className="overlay__loading-text">Please wait...</div>
        </div>
      </div>
      <Head>
        <title>Log in | Have Halal Will Travel</title>
      </Head>
      <div className="auth-page__wrapper">
        <BusinessSidebar />
        <div className="auth-page__content">
          <div className="content-wrapper content-login">
            <div className="auth-content">{handleStepContentActive()}</div>
          </div>
        </div>
        {isMobile && <BusinessMobileHeader />}
      </div>
    </div>
  );
}

export default SignIn;
